import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import numeral from './lib/numeral'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
})

const Response = ({ classes, response }) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>EAN</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>Preis</TableCell>
          <TableCell numeric>Verfügbarkeit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(response).map(puid => {
          const {
            productNameWithManufacturer,
            ean,
            manufacturerSKU,
            weightedAveragePrice,
            totalStock,
          } = response[puid]

          return (
            <TableRow key={puid}>
              <TableCell component="th" scope="row">
                {productNameWithManufacturer}
              </TableCell>
              <TableCell component="th" scope="row">
                {ean}
              </TableCell>
              <TableCell>{manufacturerSKU}</TableCell>

              <TableCell numeric>
                {numeral(parseFloat(weightedAveragePrice)).format('0[.]00 $')}
              </TableCell>
              <TableCell numeric>{totalStock}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default withStyles(styles)(Response)
