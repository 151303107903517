import axios from 'axios'

const URL = process.env.REACT_APP_API_URL
const resellerId = process.env.REACT_APP_RESELLER_ID

export const getPriceData = values =>
  axios.get(`${URL}/it_scope_price/${resellerId}`, {
    params: {
      ...values,
    },
  })
